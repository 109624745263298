import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { IntlProvider, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Route, withRouter, Redirect, Outlet } from "react-router-dom";
import { PopupboxContainer } from "react-popupbox";
import Spinner from "react-spinkit";
import SharedRoutes from "./SharedRoutes";
import ErrorMessage from "./support/components/errorMessage";
import Footer from "./support/components/Footer";
import Admissions from "./admissions";
import Applications from "./staff/admissions";
import Admin from "./staff/admin";
import Institution from "./staff/institutions";
import Scholarship from "./scholarship";
import Messaging from "./messaging";
import Advisors from "./advisors";
import PrivateRoute from "./support/components/privateRoute";
import NewAccount from "./publicPages/NewAccount";
import Login from "./publicPages/Login";
import { loginAction, logoutAction } from "./redux/login";
import AdvisorProvider from "./advisors/context";
import Header from "./common/header";
import { ajaxStatusErrorAction } from "./redux/ajax";
import { getCode } from "./api/login";
import { loadIdentifiers } from "./admissions/logic/admissionsRedux";
import { ToastContainer } from "react-toastify";
import { loadCodeTablesAction } from "./redux/codeTables";
import { UserType } from "./support/enums";
import SecurityProvider, { Navigation } from "./support/security";
import ForgotUserName from "./publicPages/ForgotUserName/ForgotUserName";
import ForgotPassword from "./publicPages/ForgotPassword";
import Terms from "./publicPages/terms";
import Verify from "./publicPages/Verify";
import AcceptInvite from "./publicPages/AcceptInvite";
import ApplicationRejected from "./publicPages/ApplicationRejected";
import ResetPassword from "./publicPages/ResetPassword";
import VerifyEmail from "./publicPages/VerifyEmail";
import ResetPasswordUserName from "./publicPages/ResetPasswordUserName";

import "./App.css";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import "font-awesome-animation/dist/font-awesome-animation.css";
import "react-popupbox/dist/react-popupbox.css";
import "react-table/react-table.css";
import StaffEmail from "./publicPages/StaffEmail";

import RouteListener from "./support/components/routeListener";
import { Hidden } from "@material-ui/core";

const isInternetExplorer = false || !!document.documentMode;



const BrowserNotSupported = () => {
    return (
        <div
            style={{
                height: "100vh",
            }}
        >
            <FormattedMessage
                id="BrowserNotSupported"
                defaultMessage="[Internet Explorer is not supported, please use another browser.]"
                children={(formattedMessage) => (
                    <span
                        style={{
                            fontWeight: "bold",
                            position: "relative",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        {formattedMessage}
                    </span>
                )}
            />
        </div>
    );
};

// const history = createBrowserHistory();
// history.listen((location, action) => {
//     var workArea = document.getElementsByClassName("Workarea")[0];
//     if (workArea) {
//         if (workArea.scrollTo) {
//             workArea.scrollTo(0, 0);
//         } else if (workArea.scrollIntoView) {
//             workArea.scrollTop = 0;
//         }
//     }
// });

Modal.setAppElement("#root");

class App extends Component {
    constructor(props) {
        super(props);

        let user = null;
        const cachedUser = sessionStorage.getItem("user");
        if (cachedUser !== null) {
            user = JSON.parse(cachedUser);
        }
        if (user !== null) {
            this.props.login(user);
        }

        this.state = {
            user,
            ajaxCallsInProgress: 0,
            showDialog: false,
            dialogText: "",
            messageCleared: false,
        };
        this.onLoginComplete = this.onLoginComplete.bind(this);
    }

    timeoutId = null;

    static getDerivedStateFromProps = (props, state) => {
        const newState = {
            ajaxCallsInProgress: props.ajaxCallsInProgress,
            messageCleared: false,
        };

        return newState;
    };

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            var workArea = document.getElementsByClassName("Workarea")[0];
            if (workArea) {
                if (workArea.scrollTo) {
                    workArea.scrollTo(0, 0);
                } else if (workArea.scrollIntoView) {
                    workArea.scrollTop = 0;
                }
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidUpdate(prevProps, prevState) {
        

        if (prevProps.user !== this.props.user) {
            if (this.props.user === null) {
                this.setState({ user: null });
            } else {
                this.onLoginComplete(this.props.user);
            }
        }
        if (prevProps.errorMessage !== this.props.errorMessage && this.props.errorMessage) {
            this.setState({ showDialog: true, dialogText: this.props.errorMessage });
        }
    }
    
    onCloseDialog = () => {
        this.setState(
            {
                showDialog: false,
                dialogText: "",
                messageCleared: true,
            },
            () => {
                this.props.ajaxStatusErrorAction("");
            }
        );
    };

    onError = (message) => {
        //TODO:
        //console.log(message);
    };

    onTokenTimeout = () => {
        this.props.logout();
        this.setState({
            showDialog: true,
            dialogText: "Session expired",
        });
    };
    onNewAdmission =  (app) => {
        this.setState({ admissions: app })
    };
    onLoginComplete = async (user) => {
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(this.onTokenTimeout, user.expireInSeconds * 1000);

        if (user && this.props.admissions === null) {
            if (user.userProfile.userType === UserType.Parent) {
                this.props.loadIdentifiers();
            } 

            this.props.loadCodeTables();
        }
        
        this.setState({
            user,
        });
    };

    render() {
        const { locale, messages } = this.props;
        
        if (!messages) return null;
        

        const isAuthenticated = this.state.user !== null;

        return (
            <IntlProvider locale={locale} messages={messages[locale]}>
                <SecurityProvider onError={this.onError}>
                    {!isInternetExplorer ? (
                        <div className={`App ${isAuthenticated ? "auth" : ""}`}>
                            <RouteListener />
                            <ToastContainer autoClose={2000} />
                            {isAuthenticated && <Header user={this.props.user} />}
                            <div className="middle-container">
                                <Hidden smDown>{isAuthenticated && <Navigation />}</Hidden>

                                <div className="Workarea">
                                    <Route exact path="/index.html" render={() => <Redirect to="/login" />} />
                                    <Route exact path="/" render={() => <Redirect to="/login" />} />
                                    <Route path="/newaccount" component={NewAccount} />
                                    <Route path="/login" component={Login} />
                                    <Route path="/stafflogin" render={(routeProps) => <Login isStaff={true} {...routeProps} />} />
                                    <Route path="/forgotusername" component={ForgotUserName} />
                                    <Route path="/forgotpassword" component={ForgotPassword} />
                                    <Route path="/terms" component={Terms} />
                                    <Route path="/verify/:userName" component={Verify} />
                                    <Route path="/acceptinvite" component={AcceptInvite} />
                                    <Route path="/status" component={ApplicationRejected} />
                                    <Route path="/resetpassword" component={ResetPassword} />
                                    <Route path="/verifyemail" component={VerifyEmail} />
                                    <Route path="/staffemail" component={StaffEmail} />
                                    <Route path="/resetusername/:userName" component={ResetPasswordUserName} />
                                    <PrivateRoute path="/admissions" render={(props) => <Admissions {...props} onNewAdmission={this.onNewAdmission} topUser={this.state.user} />}  />
                                    <PrivateRoute path="/applications" render={() => <Applications topUser={this.state.user} />}  />
                                    <PrivateRoute path="/admin" component={Admin} />
                                    <PrivateRoute path="/institution" component={Institution} />
                                    <PrivateRoute path="/finance" component={Institution} />
                                    <PrivateRoute path="/scholarship" render={() => <Scholarship topUser={this.state.user} />} />
                                    <PrivateRoute path="/messages" component={Messaging} />
                                    <PrivateRoute
                                        path={["/advisor", "/events"]}
                                        render={(props) => <SharedRoutes topUser={this.state.user} />}
                                    />
                                   
                                </div>
                            </div>
                            <Footer />
                            {this.props.ajaxCallsInProgress !== 0 ? (
                                <React.Fragment>
                                    <Spinner name="line-scale" color="#3cc6e8" fadeIn="none" style={{ zIndex: 100 }} />
                                    <div className="Loading"></div>
                                </React.Fragment>
                            ) : null}
                            <ErrorMessage show={this.state.showDialog} message={this.state.dialogText} onClose={this.onCloseDialog} />
                            <PopupboxContainer />
                        </div>
                    ) : (
                        <BrowserNotSupported />
                    )}
                </SecurityProvider>
            </IntlProvider>
        );
    }
}

App.propTypes = {
    locale: PropTypes.string.isRequired,
    messages: PropTypes.object,
    user: PropTypes.object,
    ajaxCallsInProgress: PropTypes.number.isRequired,
};

const mapStateToProps = ({ admissions, locale, languageMessages, user, ajaxCallsInProgress, errorMessage }) => {
    return {
        admissions,
        locale,
        messages: languageMessages,
        user,
        ajaxCallsInProgress,
        errorMessage,
    };
};

const mapDispatchToProps = (dispatch) => ({
    login: (user) => dispatch(loginAction(user)),
    logout: () => dispatch(logoutAction()),
    loadIdentifiers: () => dispatch(loadIdentifiers()),
    loadCodeTables: () => dispatch(loadCodeTablesAction()),
    ajaxStatusErrorAction: () => dispatch(ajaxStatusErrorAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
