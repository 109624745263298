import React, { Component, } from "react";
import Link from "@material-ui/core/Link";

import { Tabs, Tab } from '@mui/material';

import { Grid,  Button, Typography } from '@material-ui/core';
import moment from "moment";
import "./tabbedProfile.css";
import { Progress, Academics, ActivitiesList, EventsList, Notes, PostSecondary, GeneralInfoForm, StudentProfileForm, StudentProfileFormPs, Progress2Exp } from "./components";
import AdvisorContext from "../context/context";
import { getUserAdvisorEventHistory, updateStudentProfile, getStudentById, getUserEventhistory, getUserMessageHistory } from "../api.js";
import { eventType } from "../enums.js";
import LoadingIndicator from "../../common/loadingIndicator";
import enumHelper from "../../support/logic/enumHelper";
import { Language } from "../../admissions/logic/enums";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Export2PDF from "../../common/export";
import { StudentStatus } from "../../support/enums";
const languageOptions = enumHelper
    .getSelectOptionsList(Language);
class AdvisorStudent extends Component {

    static contextType = AdvisorContext;

    constructor(props) {
        super(props);

        this.state = {

            
            activeProfile: this.props.profile,
            tab: 0,
            infoVis: "tabVisible",
            eventsVis: "tabHidden",
            activityVis: "tabHidden",
            postSecVis: "tabHidden",
            academicVis: "tabHidden",
            notesVis: "tabHidden",
            progressVis: "tabHidden",
            exportTitle: "",
            changed: false,
            description: "",
            activity: [],
            notes: [],
            events: [],
            messages:[],
            profileLoaded: false, 
            ipedName: "",
            ipedFound: false
        };
    }



    componentDidMount = async () => {
        let profileLoaded = false;

        const prof = await getStudentById(this.props.match.params.id)

        const histCall = await getUserAdvisorEventHistory(this.props.match.params.id);
        const messCall = await getUserMessageHistory(this.props.match.params.id);
        const history = histCall.data.result;

        const evtHistCall = await getUserEventhistory(this.props.match.params.id);

        const evtHist = evtHistCall.data.result;
        const title = prof.data.result.userProfile.firstName + '-' + prof.data.result.userProfile.lastName + '-' + prof.data.result.studentProfile.crusaderId;

        this.setState({ messages: messCall.data.result ,exportTitle: title, activeProfile: prof.data.result, profileLoaded: true, activity: history.filter(flt => flt.type != eventType.StudentNote), notes: history.filter(flt => flt.type == eventType.StudentNote), events: evtHist });

    }
    componentDidUpdate(prevProps, prevState) {

        if (this.context.loaded && this.state.profileLoaded && this.state.ipedName == "" && this.state.activeProfile.studentProfile.institutionIPEDNo != null && !this.state.ipedFound) {

            let ipedName = "";
            

            const ipeds = this.context.ipedList.filter(flt => flt.ipedNo == this.state.activeProfile.studentProfile.institutionIPEDNo);
                if (ipeds.length == 1) {
                    ipedName = ipeds[0].name;

            }
            this.setState({ ipedName: ipedName, ipedFound: true });
            }
        
       

    }

    getLanguage = (input) => {

        const lan = languageOptions.filter(l => l.value == input);
        return lan.length > 0 ? lan[0].label : "English";
    }


    setTab = (event, newVal) => {
        let infoVis = "tabHidden";
        let eventsVis = "tabHidden";
        let activityVis = "tabHidden";
        let postSecVis = "tabHidden";
        let academicVis = "tabHidden";
        let notesVis = "tabHidden";
        let progressVis = "tabHidden";
        switch (newVal) {
            case 0:
               
                infoVis = "tabVisible";

                break;
            case 1:
                eventsVis = "tabVisible";
                break;
            case 2:
                activityVis = "tabVisible";
                break;
            case 3:
                postSecVis = "tabVisible";
                break;
            case 4:
                academicVis = "tabVisible";
                break;
            case 5:
                notesVis = "tabVisible";
                break;
            case 6:
                progressVis = "tabVisible";
                break;
            
            default:
                
                break;


        }

        this.setState({ tab: newVal, infoVis: infoVis, eventsVis: eventsVis, activityVis: activityVis, postSecVis: postSecVis, academicVis: academicVis, notesVis: notesVis, progressVis });
    }

    renderCrusaderId = () => {



        if (!this.state.activeProfile.studentProfile.nonOnwardStudent) {
            return (
                <span  >

                    {this.state.activeProfile.studentProfile.crusaderId}

                </span>
            );
        } else {
            return (
                <span  >

                    {this.state.activeProfile.studentProfile.crusaderId} - Non Program Student <AccountCircleIcon style={{ color: '#e2a331' }} />

                </span>
            );
        }
       
    };

    render() {
        if (!this.state.profileLoaded ) {
            
            return <LoadingIndicator loading />;

        }
        
        return (

            <div className="container">
                <Link
                    className={"dashboardLink"} onClick={this.props.goBack}
                >
                    &lt; Back
                </Link> <br />
               
                    
        <Grid container spacing={2}>

                                        
                                                {this.state.activeProfile
                        ? <Grid item xs={8}>
                                            <Typography variant="h6">
                                {this.state.activeProfile.userProfile.firstName} {this.state.activeProfile.userProfile.lastName} -  {this.renderCrusaderId()}
                            </Typography><br />
                            Status: {enumHelper.getDisplayName(StudentStatus, this.state.activeProfile.studentProfile.status ) }
                        </Grid>        : null}
                                          
                    <Grid item xs={4}>
                        <Typography variant="h6">
                            Family
                        </Typography>
                        <Typography variant="body1">
                            Prefered Languange: {this.getLanguage(this.state.activeProfile.family.primaryLanguage) == "Other" ? this.state.activeProfile.family.otherLanguage : this.getLanguage(this.state.activeProfile.family.primaryLanguage)}
                            </Typography>  <br />
                        {this.state.activeProfile.family.primaryParent.id != '00000000-0000-0000-0000-000000000000' ?  <Link
                            className={"dashboardLink"} onClick={() => this.props.openProfile(this.state.activeProfile, "PrimaryParent")}
                >
                            Primary Guardian: {this.state.activeProfile.family.primaryParent.firstName} {this.state.activeProfile.family.primaryParent.lastName}
                        </Link> : null}<br />
                        {this.state.activeProfile.family.secondaryParent.id != '00000000-0000-0000-0000-000000000000' ? <Link
                            className={"dashboardLink"} onClick={() => this.props.openProfile(this.state.activeProfile, "SecondaryParent")}
                        >
                            Secondary Guardian: {this.state.activeProfile.family.secondaryParent.firstName} {this.state.activeProfile.family.secondaryParent.lastName}
                        </Link> : null}<br />
                    </Grid>


            <Grid item xs={12}>
                            <Tabs
                                value={this.state.tab}
                                onChange={this.setTab}
                                variant="fullWidth"
                                sx={{
                                    '.MuiTabs-flexContainer': {
                                        justifyContent: 'space-evenly',
                                    },
                                    '.MuiTab-root': {
                                        border: '1px solid #000',
                                        borderBottom: 'none',
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        marginRight: '4px',
                                        '&:last-child': {
                                            marginRight: 0,
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#123225', 
                                            color: '#fff',
                                        },
                                        '&:hover': {
                                            backgroundColor: '#123225', 
                                            color: '#fff',
                                            opacity: 1,
                                        },
                                    },
                                }}
                            >
                                <Tab label="INFO" />
                                <Tab label="EVENTS" />
                                <Tab label="ACTIVITIES" />
                                <Tab label="POST-SECONDARY" />
                                <Tab label="ACADEMICS" />
                                <Tab label="NOTES" />
                                <Tab label="PROGRESS" />
                            </Tabs>
            </Grid>
                    <Grid container spacing={2} className={this.state.infoVis}>
                        <Grid item xs={12 }>
                            <Typography variant="h5" color="secondary" >
                                General Information

                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <Typography variant="h7" color="secondary" gutterBottom>
                            Total Hours: {this.state.activeProfile.studentContactInfo.eventHours + this.state.activeProfile.studentContactInfo.advisorHours}
                        </Typography>
                        </Grid>
                        <Grid item xs={12}>
                        <GeneralInfoForm profile={this.state.activeProfile} id={this.props.match.params.id} type={"Student"} />
                    </Grid>   
            </Grid>
            <Grid container spacing={2} className={this.state.eventsVis}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary" gutterBottom>
                        Events

                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h7" color="secondary" gutterBottom>
                            Total Event Hours: {this.state.activeProfile.studentContactInfo.eventHours}
                            </Typography></Grid>
                        <Grid item xs={12}>
                            <EventsList data={this.state.events} openEvent={this.props.openEvent} />
                </Grid>
            </Grid>
            <Grid container spacing={2} className={this.state.activityVis}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary" gutterBottom>
                                Activities

                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h7" color="secondary" gutterBottom>
                                Total Advisor Hours: {this.state.activeProfile.studentContactInfo.advisorHours }

                            </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            <Button onClick={() => this.props.newEntry(this.state.activeProfile)} className="profileActionButton">Add Entry</Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button onClick={() => this.props.onContactAttempt(this.state.activeProfile)} className="profileActionButton">Communication Attempt</Button>
                        </Grid>
                            <Grid item xs={12}>
                            <ActivitiesList openEntry={this.props.openEntry} data={this.state.activity} message={this.state.messages} openMessageEntry={this.props.openMessageEntry } />
                </Grid>
                        </Grid>
                        <Grid container spacing={2} className={this.state.postSecVis}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" gutterBottom>
                                    Post-secondary

                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <StudentProfileFormPs student={this.state.activeProfile} ipedName={ this.state.ipedName} onUpdate={updateStudentProfile} />
                                <PostSecondary />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={this.state.academicVis}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" gutterBottom>
                                    Academics

                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <StudentProfileForm student={this.state.activeProfile}  onUpdate={updateStudentProfile} />
                            <Academics student={this.state.activeProfile}  />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={this.state.notesVis}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary" gutterBottom>
                                    Notes

                            </Typography>
                            <br/>
                            <Button onClick={() => this.props.newNote(this.state.activeProfile)} className="profileActionButton">Add Note</Button>
                            <Notes data={this.state.notes} openNote={this.props.openNote} />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className={this.state.progressVis}>
                            <Grid item xs={11}>
                                <Typography variant="h5" color="secondary" gutterBottom>
                                    Progress

                                </Typography>
                            <Progress profile={this.state.activeProfile} />
                        </Grid><Grid item xs={1}>
                            <br />
                            <br />
                            <Export2PDF fileName={this.state.exportTitle}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}> <Typography variant="h4" style={{ textAlign: "center", }} >
                                        Progress
                                    </Typography></Grid>
                                    <Grid item xs={9}> 
                                <Typography variant="h6">
                                            &nbsp; {this.state.activeProfile.userProfile.firstName} {this.state.activeProfile.userProfile.lastName} -  {this.renderCrusaderId()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {moment(Date.now()).format("M/D/YY")}
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <Progress2Exp profile={this.state.activeProfile} />
                                    </Grid>
                                </Grid>
                            </Export2PDF>
                            </Grid>
                        </Grid>
        </Grid>
                


                           
            </div>);
    }

}
export default AdvisorStudent;