// SharedRoutes.jsx
import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./support/components/privateRoute";
import AdvisorProvider from "./advisors/context";
import Advisor from "./advisors";   // your Advisor component
import Events from "./events";       // your Events component

const SharedRoutes = (props) => {
    console.log(props.topUser);
    
    return (
        <AdvisorProvider>
            <Switch>
                <PrivateRoute path="/advisor" component={Advisor} topUser={props.topUser} />
                <PrivateRoute path="/events" render={() => <Events topUser={props.topUser} />} />
            </Switch>
        </AdvisorProvider>
    );
};

export default SharedRoutes;
