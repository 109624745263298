import React, { Component } from "react";
import Link from "@material-ui/core/Link";
import moment from 'moment-timezone';
import "./index.css";
import FontAwesome from "react-fontawesome";

import { Grid, Checkbox, TextField, Button, Typography, IconButton, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import Modal from "react-modal";
import { Tabs, Tab } from '@mui/material';
import  *  as Api from "./api.js";
import ReactTable from "react-table";


const getDob = (values) => {
    return moment(values.dateOfBirth).tz('America/New_York').format('MM/DD/YYYY');
};

class AdvancedSearch extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            showSearch: false,
            searchTab: 0,
            searchStudents: [],
            searchPriParents: [],
            studVis: "tabVisible",
            parVis: "tabHidden",
            basVis: "tabVisible",
            advVis: "tabHidden",
            searchVal: "",
            schools: [],
            inst: [],
            iped: [],
            showAdvanced: false,
            fnameVal: "",
            lnameVal: "",
            mobileVal: "",
            emailVal: "",
            gradeVal: "",
            schIdVal: null,
            loaded: true
        };
    }

    closeSearch = () => {
        this.setState({
            showSearch: false,
            searchTab: 0,
            searchStudents: [],
            searchPriParents: [],
            studVis: "tabVisible",
            parVis: "tabHidden",
            basVis: "tabVisible",
            advVis: "tabHidden",
            searchVal: "",
            showAdvanced: false,
            fnameVal: "",
            lnameVal: "",
            mobileVal: "",
            emailVal: "",
            gradeVal: "",
            schIdVal: null,
            loaded: true
        }, () => {
            
            this.props.closeSearch();
        });
    }


    componentDidMount = async () => {
       
        const sch = await Api.getAllSchools();
        const inst = await Api.getAllInstitutions();
        const iped = await Api.getIpeds();


        this.setState({
            schools: sch.data.result, inst: inst.data.result, iped: iped.data.result
        }, () => {

            
        });

        

        
    }
    componentDidUpdate = (prevProps, prevState) => {
        
        if (prevProps.searchTab != this.props.searchTab) {
            if (this.props.searchTab != null) {
                console.log("Setting tab");
                console.log(this.props.searchTab);
                const evt = {};
                this.setTab(evt, this.props.searchTab);
            }
        }

    }
    runSearch = async () => {

        this.setState({

            loaded: false
        }, async () => {

            const res = await Api.advisorSearch({ text: this.state.searchVal });

            this.setState({ searchStudents: res.data.result.students, searchPriParents: res.data.result.parents, loaded: true });
        });

        
    }

    runAdvancedSearch = async () => {

        this.setState({

            loaded: false
        }, async () => {

            let input = {};
            if (this.state.studVis == "tabVisible") {
                input = {
                    firstName: this.state.fnameVal,
                    lastName: this.state.lnameVal,
                    mobilePhone: this.state.mobileVal,
                    email: this.state.emailVal,
                    currentGrade: this.state.gradeVal,
                    schoolId: this.state.schIdVal
                }
            } else {
                input = {
                    firstName: this.state.fnameVal,
                    lastName: this.state.lnameVal,
                    mobilePhone: this.state.mobileVal,
                    email: this.state.emailVal,
                    currentGrade: null,
                    schoolId: null
                }
            }

            const res = await Api.advancedAdvisorSearch(input);

            this.setState({ searchStudents: res.data.result.students, searchPriParents: res.data.result.parents, loaded: true });
        });

        
    }
    
    updateSearch = (event) => {



        this.setState({ searchVal: event.target.value.toLowerCase() });


    }
    updateFirstName = (event) => {



        this.setState({ fnameVal: event.target.value.toLowerCase() });


    }
    updateLastName = (event) => {



        this.setState({ lnameVal: event.target.value.toLowerCase() });


    }
    updateMobile = (event) => {



        this.setState({ mobileVal: event.target.value.toLowerCase() });


    }
    updateEmail = (event) => {



        this.setState({ emailVal: event.target.value.toLowerCase() });


    }

    getSchoolName = (rec) => {
        console.log(rec);
        // If InstIp is provided, search the iped array
        if (rec.institutionIPEDNo !== null && rec.institutionIPEDNo !== undefined && rec.institutionIPEDNo !== "") {
            const ipedRecord = this.state.iped.find(item => item.ipedNo === rec.institutionIPEDNo);
            console.log("Returning Iped");
            return ipedRecord ? ipedRecord.name : "";
        }

        // If InstIp is not provided, but InstId is provided, search the inst array
        if (rec.selectedInstitutionId !== null && rec.selectedInstitutionId !== undefined && rec.selectedInstitutionId !== "") {
            const instRecord = this.state.inst.find(item => item.id === rec.selectedInstitutionId);
            console.log("Returning Inst");
            return instRecord ? instRecord.name : "";
        }

        // If both InstIp and InstId are not provided, but SchId is, search the schools array
        if (rec.schoolId !== null && rec.schoolId !== undefined && rec.schoolId !== "") {
            const schoolRecord = this.state.schools.find(item => item.id === rec.schoolId);
            console.log("Returning School");
            return schoolRecord ? schoolRecord.name : "";
            
        }

        // If all three are null or empty, return an empty string
        return "";
    };
    advancedChanged = () => {
        let basVis = !this.state.showAdvanced ? "tabHidden" : "tabVisible";
        let advVis = this.state.showAdvanced ? "tabHidden" : "tabVisible";
        
                this.setState({ showAdvanced: !this.state.showAdvanced, basVis, advVis });
    }
    setTab = (event, newVal) => {
        let studVis = "tabHidden";
        let parVis = "tabHidden";

        switch (newVal) {
            case 0:
                studVis = "tabVisible";
                parVis = "tabHidden";

                break;
            case 1:
                studVis = "tabHidden";
                parVis = "tabVisible";

                break;

            default:
                studVis = "tabVisible";
                parVis = "tabHidden";
                break;


        }

        this.setState({ searchTab: newVal, parVis: parVis, studVis: studVis });
    }
    handleGradeChange = (event) => {
        this.setState({ gradeVal: event.target.value });
    };

    handleSchoolChange = (event) => {
        this.setState({ schIdVal: event.target.value });
    };

    render() {
        console.log(this);



        let stuColumns = [
            {
                Header: "Onward Id",
                accessor: "crusaderId",
                width: 100,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "Student")}>
                        {row.original.crusaderId}
                    </Link>
                ),

            },

            {
                Header: "Name",
                accessor: "lastName",
                width: 250,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "Student")}>
                        {row.original.lastName}, {row.original.firstName}
                    </Link>
                ),

            },
            {
                Header: "Phone",
                accessor: "mobilePhone",
                width: 125,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "PrimaryParent")}>
                        {row.original.mobilePhone}
                    </Link>
                ),

            },
            {
                Header: "Email",
                accessor: "email",
                width: 450,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "PrimaryParent")}>
                        {row.original.email}
                    </Link>
                ),

            },
            {
                Header: "School",
                accessor: "schoolId",
                width: 450,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "Student")}>
                        {this.getSchoolName(row.original)}
                    </Link>
                ),

            },
            {
                Header: "Grade",
                accessor: "currentGrade",
                width: 75,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "Student")}>
                        {row.original.currentGrade}
                    </Link>
                ),

            },
            {
                Header: "DOB",
                accessor: "dateOfBirth",
                width: 100,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "Student")}>
                        {getDob(row.original)}
                    </Link>
                ),

            },
            {
                Header: "Parent 1",
                accessor: "f1Name",
                width: 200,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick({ id: row.original.f1Id }, "PrimaryParent")}>
                        {row.original.f1Name}
                    </Link>
                ),

            },
            
        ];

        let parColumns = [
            

            {
                Header: "Name",
                accessor: "lastName",
                width: 250,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "PrimaryParent")}>
                        {row.original.lastName}, {row.original.firstName}
                    </Link>
                ),

            },
            
            {
                Header: "Phone",
                accessor: "mobilePhone",
                width: 125,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "PrimaryParent")}>
                        {row.original.mobilePhone}
                    </Link>
                ),

            },
            {
                Header: "Email",
                accessor: "email",
                width: 450,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick(row.original, "PrimaryParent")}>
                        {row.original.email}
                    </Link>
                ),

            },
            {
                Header: "Student 1",
                accessor: "f1Name",
                width: 150,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick({ id: row.original.f1Id }, "Student")}>
                        {row.original.f1Name}
                    </Link>
                ),

            },
            {
                Header: "Student 2",
                accessor: "f2Name",
                width: 150,
                Cell: row => (
                    <Link style={{
                        display: 'block',           // Makes the element respect width & overflow
                        width: '100%',              // Fills the available cell width
                        overflow: 'hidden',         // Hides the overflowing text
                        whiteSpace: 'nowrap',       // Prevents the text from wrapping
                        textOverflow: 'ellipsis',   // Adds an ellipsis (...) when text is truncated
                    }} key={row.original.id} onClick={() => this.props.resultClick({ id: row.original.f2Id }, "Student")}>
                        {row.original.f2Name}
                    </Link>
                ),

            },
        ];


       // console.log(this);
        const customStyles = {
            content: {
                top: '30px',
                left: '5%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-5%',

                padding: 0, // Remove padding inside the modal content
                overflow: 'auto',
                // Set a max height and width if necessary
                maxHeight: '90vh',
                maxWidth: '90vw',
            },
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.75)' // Darken the background behind the modal
            }
        };

        return (

            <Modal isOpen={this.props.isOpen} ariaHideApp={false} style={customStyles}>
                <div style={{ display: 'flex', padding: '20px' }}> {/* Add padding to the top */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{this.props.label}</Typography> Advanced Search: <Checkbox checked={this.state.showAdvanced} onChange={this.advancedChanged } />
                        </Grid>
                        <Grid container spacing={1} alignItems="center" className={this.state.basVis}>
                            <Grid item xs={11}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter first or last name to search"
                                    name="title"
                                    onBlur={this.updateSearch}
                                    onChange={this.updateSearch}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton aria-label="search" onClick={() => this.runSearch()}>
                                    <FontAwesome name="search" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="center" className={this.state.advVis}>
                            <Grid item xs={11}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        
                                            <InputLabel id="my-select-label">First Name</InputLabel>
                                <TextField
                                            fullWidth
                                    variant="outlined"
                                    placeholder="Enter first name to search"
                                    name="title"
                                    onBlur={this.updateFirstName}
                                    onChange={this.updateFirstName}
                                            />
                                    
                                </Grid>
                                    <Grid item xs={4}>
                                        
                                            <InputLabel id="my-select-label">Mobile Phone</InputLabel>
                                    <TextField
                                    fullWidth
                                        variant="outlined"
                                        placeholder="Enter Mobile Phone to search"
                                        name="title"
                                        onBlur={this.updateMobile}
                                        onChange={this.updateMobile}
                                    />
                                    </Grid>
                                    <Grid item xs={4} className={this.state.parVis}></Grid>
                                    <Grid item xs={4} className={this.state.studVis}>
                                        <InputLabel id="my-select-label">Grade</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="my-select-label"
                                            value={this.state.gradeVal}
                                            onChange={this.handleGradeChange}
                                            label="Option"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="5">5</MenuItem>
                                            <MenuItem value="6">6</MenuItem>
                                            <MenuItem value="7">7</MenuItem>
                                            <MenuItem value="8">8</MenuItem>
                                            <MenuItem value="9">9</MenuItem>
                                            <MenuItem value="10">10</MenuItem>
                                            <MenuItem value="11">11</MenuItem>
                                            <MenuItem value="12">12</MenuItem>
                                            <MenuItem value="13">13</MenuItem>
                                            <MenuItem value="14">14</MenuItem>
                                            <MenuItem value="15">15</MenuItem>
                                            <MenuItem value="16">16</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={4}>
                                        
                                            <InputLabel id="my-select-label">Last Name</InputLabel>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Enter last name to search"
                                            name="title"
                                            onBlur={this.updateLastName}
                                            onChange={this.updateLastName}
                                        />
                                    </Grid>    <Grid item xs={4}>
                                        
                                            <InputLabel id="my-select-label">Email</InputLabel>
                                    <TextField
                                    fullWidth
                                        variant="outlined"
                                        placeholder="Enter Email to search"
                                        name="title"
                                        onBlur={this.updateEmail}
                                        onChange={this.updateEmail}
                                    />
                                    </Grid>
                                    <Grid item xs={4} className={this.state.parVis}></Grid>
                                    <Grid item xs={4} className={this.state.studVis}>
                                        <InputLabel id="my-select-label">School</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="my-select-label"
                                            value={this.state.schIdVal}
                                            onChange={this.handleSchoolChange}
                                            label="Option"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {this.state.schools.map((sch) => (


                                                <MenuItem value={sch.id}>{sch.name}</MenuItem>


                                            ))}
                                            {this.state.inst.map((sch) => (


                                                <MenuItem value={sch.id}>{sch.name}</MenuItem>


                                            ))}


                                        </Select>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton aria-label="search" onClick={() => this.runAdvancedSearch()}>
                                    <FontAwesome name="search" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <div className="actionButtonWrapper">
                            <Button onClick={this.closeSearch} className="fieldSelectorButton">Cancel</Button>
                        </div>
                        <Grid item xs={12}>
                            <Tabs value={this.state.searchTab} onChange={this.setTab} aria-label="Search" variant="fullWidth"
                                sx={{
                                    '.MuiTabs-flexContainer': {
                                        justifyContent: 'space-evenly',
                                    },
                                    '.MuiTab-root': {
                                        border: '1px solid #000',
                                        borderBottom: 'none',
                                        borderTopLeftRadius: '10px',
                                        borderTopRightRadius: '10px',
                                        marginRight: '4px',
                                        '&:last-child': {
                                            marginRight: 0,
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#123225',
                                            color: '#fff',
                                        },
                                        '&:hover': {
                                            backgroundColor: '#123225',
                                            color: '#fff',
                                            opacity: 1,
                                        },
                                    },
                                }}
                            >
                                <Tab label="Students" />
                                <Tab label="Guardians" />


                            </Tabs>
                        </Grid>
                        {this.state.loaded ? null : <div>Loading...</div> }
                        <Typography>{this.props.disclaimer}</Typography>
                        <Grid item xs={12} className={this.state.parVis}>
                            {this.state.searchPriParents.length == 0 ? <Typography variant="normal">No Guardian Records</Typography> : <ReactTable
                                data={this.state.searchPriParents}
                                minRows={0}
                                columns={parColumns}
                                defaultPageSize={50}
                                className="-striped -highlight tableWrapper"
                            />}
                        </Grid>
                        <Grid container spacing={1} className={this.state.studVis}>
                            {this.state.searchStudents.length == 0 ? <Typography variant="normal">No Student Records</Typography> : <ReactTable
                                data={this.state.searchStudents}
                                minRows={0}
                                columns={stuColumns}
                                defaultPageSize={50}
                                className="-striped -highlight tableWrapper"
                            />}
                        </Grid>
                    </Grid>

                </div>


            </Modal>


             );
    };



}


export default AdvancedSearch;
