import React, { Component, Fragment } from "react";

import ReactTable from "react-table";
import moment from "moment-timezone";

import EditIcon from '@mui/icons-material/Edit';
import { Tabs, Tab } from '@mui/material';
import { Grid, Button, Typography } from '@material-ui/core';
import * as api from '../../api/programEvents';





class AddFamilyDialog extends Component {
   

    constructor(props) {
        super(props);

        this.state = {
            actVis: "tabVisible",
            msgVis: "tabHidden",
            tab: 0,
            parents: [],
            students: []


        };
    }

    displayDate = (val) => {
        if (!val) return "";


        return moment.utc(
            val.endsWith("Z") ? val : `${val}Z`
        )
            .tz("America/New_York")
            .format("M/D/YY h:mm a");
    };

    componentDidMount = async () => {
        
        const fam = await api.getFamiliesByProfile(this.props.userProfileId);
        
        const mem = fam.data.result[0].familyMembers;
       
        if (mem != null) {

            const stu = mem.filter(flt => flt.type == 4 && flt.userProfile.userType == 4);
            const par = mem.filter(flt => flt.type != 4);

            this.setState({ parents: par, students: stu});
        }

    }
    componentDidUpdate = async (prevProps, prevState) => {
        



    }


    setTab = (event, newVal) => {
        let actVis = "tabHidden";
        let msgVis = "tabHidden";

        switch (newVal) {
            case 0:

                actVis = "tabVisible";

                break;
            case 1:
                msgVis = "tabVisible";
                break;


            default:

                break;


        }

        this.setState({ tab: newVal, actVis: actVis, msgVis: msgVis });
    }

    render() {

       

        return <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>Add Family Member</Typography></Grid>
                <Grid item xs={12}>
                    <Tabs value={this.state.tab} onChange={this.setTab} aria-label="Activity Sections" variant="fullWidth"
                        sx={{
                            '.MuiTabs-flexContainer': {
                                justifyContent: 'space-evenly',
                            },
                            '.MuiTab-root': {
                                border: '1px solid #000',
                                borderBottom: 'none',
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                marginRight: '4px',
                                '&:last-child': {
                                    marginRight: 0,
                                },
                                '&.Mui-selected': {
                                    backgroundColor: '#123225',
                                    color: '#fff',
                                },
                                '&:hover': {
                                    backgroundColor: '#123225',
                                    color: '#fff',
                                    opacity: 1,
                                },
                            },
                        }}
                    >
                        <Tab label="Parents" />
                        <Tab label="Students" />
                    </Tabs>
                </Grid>
                <Grid container spacing={2} className={this.state.actVis}>
                    <Grid item xs={12}>
                        {this.state.parents.length ? (
                            this.state.parents.map((parent, idx) => (
                                <Fragment key={idx}>
                                    <Typography variant="body1">
                                        {parent.userProfile.firstName} {parent.userProfile.lastName}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Email: {parent.userProfile.email}
                                    </Typography>
                                    <Button
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: 16 }}
                                        onClick={() => {
                                            this.props.addAttendee(parent.userProfile);
                                        }}
                                    >
                                        Add to Event
                                    </Button>
                                    <hr />
                                </Fragment>
                            ))
                        ) : (
                            <Typography variant="body2">No parents found.</Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={this.state.msgVis}>
                    <Grid item xs={12}>
                        {this.state.students.length ? (
                            this.state.students.map((student, idx) => (
                                <Fragment key={idx}>
                                    <Typography variant="body1">
                                        {student.userProfile.firstName} {student.userProfile.lastName}
                                    </Typography>
                                    {student.studentProfile && (
                                        <>
                                        <Typography variant="body2" color="textSecondary">
                                            Grade: {student.studentProfile.currentGrade}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Onward Id: {student.studentProfile.crusaderId}
                                            </Typography>
                                        </>
                                    )}
                                    <Button
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: 16 }}
                                        onClick={() => {
                                            this.props.addAttendee(student.userProfile);
                                        }}
                                    >
                                        Add to Event
                                    </Button>
                                    <hr />
                                </Fragment>
                            ))
                        ) : (
                            <Typography variant="body2">No students found.</Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>;
    }
}

export default AddFamilyDialog;


