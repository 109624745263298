import axios from "axios";

export function getIpeds() {
    return axios.get("/api/services/app/Tagging/GetIpeds");
}

export const getAllSchools = (cancelToken) => {
    return axios.get("/api/services/app/School/GetAllActive", { cancelToken });
};

export function getCityList() {
    return axios.get('/api/services/app/City/GetList');
}

export const getAllInstitutions = (cancelToken) => {
    return axios.get("/api/services/app/Institution/GetAllActive", { cancelToken });
};

export function advisorSearch(input) {

    return axios.post("/api/services/app/Advisors/AdvisorSearch", input);
};
export function advancedAdvisorSearch(input) {

    return axios.post("/api/services/app/Advisors/AdvancedAdvisorSearch", input);
};